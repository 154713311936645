// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 查询推广码列表
export const queryPromotionList = (data: any) =>
  request('/payAdminServer/market/promotion/list', null, 'POST_JSON', data)

// 查询推广码包含商品列表详情
export const queryPromotionDetail = (data: any) =>
  request('/payAdminServer/market/promotion/detail', null, 'POST_JSON', data)

// 创建推广码以及商品
export const savePromotion = (data: any) => request('/payAdminServer/market/promotion/save', null, 'POST_JSON', data)

// 推广码启用/停用操作
export const operatePromotion = (data: any) =>
  request('/payAdminServer/market/promotion/operate', null, 'POST_JSON', data)

// 推广码更新操作
export const updatePromotion = (data: any) =>
  request('/payAdminServer/market/promotion/update', null, 'POST_JSON', data)

// 推广码导出操作
export const exportPromotion = (data: any) =>
  request('/payAdminServer/market/promotion/export', null, 'POST_DOWNLOAD', data)

// 查询可推广商品列表
export const queryCanPromotionProdList = (data: any) =>
  request('/payAdminServer/market/promotion/canPromotionProdList', null, 'POST_JSON', data)
